import React, { useState, useEffect } from 'react';



function formatQuestionsForEmailHTML(questions) {
    // Start the unordered list
    let formattedQuestions = '<ul>';

    questions.forEach(question => {
        // Initialize with the question text in bold within a list item
        let formattedQuestion = `<li><strong>${question.question.slice(0, -1)}</strong>: `;

        // Collect selected answers or provided answers
        let answers = question.fields.filter(field => field.selected || field.answer)
                          .map(field => field.selected ? field.text : field.answer);

        // Combine answers, assuming multiple choices could be selected
        if (answers.length > 0) {
            formattedQuestion += answers.join(', ');
        } else {
            // If no answers are selected/provided, indicate this
            formattedQuestion += 'Geen antwoord op gegeven.';
        }

        // Close the list item
        formattedQuestion += '</li>';

        // Add this question-answer pair to the overall list
        formattedQuestions += formattedQuestion;
    });

    // Close the unordered list
    formattedQuestions += '</ul>';

    return formattedQuestions;
}



export default function Form({
    question, totalSubsidy, questions, setShowExit
}) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setShowError(false)
    }, [name, email, tel]);
    

    function SendMail(e){
        e.preventDefault();
    
        setIsSending(true);
        const formattedQuestionsHTML = formatQuestionsForEmailHTML(questions.slice(1, -1));
    
        fetch('sendEmail.php', {
            method: 'POST',
            body: JSON.stringify({name, email, tel, totalSubsidy, formattedQuestionsHTML}),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                return response.json().then(err => {
                    throw new Error(err.message);
                });
            }
            return response.json();
        })
        .then(data => {
            window.location.href = 'https://www.hetsubsidieloket.nl/bedankt-subsidiecheck/';
        })
        .catch(error => {
            console.error('Fetch Error:', error.message);
            setShowError(true);
        })
        .finally(() => {
            setIsSending(false);
        });
    }

    return (
        <form className="Answers Form" onSubmit={(e)=>{SendMail(e)}}>
            <input
                name="given-name"
                autoComplete="given-name"
                type="text"
                placeholder="Voornaam"
                value={name}
                onChange={(e)=>setName(e.target.value)}
                required
            />
            <input
                name="email"
                autoComplete="email"
                type="email"
                placeholder="E-mailadres"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
                required
            />
            <input
                name="tel"
                autoComplete="tel"
                type="text"
                placeholder="Telefoonnummer"
                value={tel}
                onChange={(e)=>setTel(e.target.value)}
                required
            />
            {
                showError
                ? <div style={{fontSize: '14px', marginTop: '20px'}}>Er is iets fout gegaan. Controleer of het mailadres klopt.</div>
                : ''
            }
            <button className='WizardButton' type='submit' disabled={isSending}>Verstuur</button>
        </form>
    );
}