import { categories, questionTypes, callbackTypes } from "../../enums";

export default function Checklist({
    question,
    nextQuestion,
    prevQuestion,
    updateQuestion,
}) {
    
    // This is bad but have no time to fix.
    function handleUpdate(fieldText) {
        question.fields.forEach((field) => {
            if (field.text === fieldText) {
                field.selected = true;
                return;
            }
            field.selected = false;
        });
        updateQuestion(question);
    }

    // Create answer component based on question type.
    let Fields = question.fields.map((field, index) => (
        <div key={index}>
            <input
                type="checkbox"
                checked={field.selected}
                onChange={() => handleUpdate(field.text)}
            />
            <label htmlFor={`checkbox-${index}`}>{field.text}</label>
        </div>
    ));

    return (
        <div className="Answers">{Fields}</div>
    );
}
