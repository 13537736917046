import React, { useState, useEffect } from 'react';
import Checklist from './Components/Questions/Checklist';
import Input from './Components/Questions/Input'
import Form from './Components/Questions/Form';
import { categories, questionTypes, initialQuestions, postcodes10000Regeling, postcodesWaardedalingsregeling } from './enums';



function GetPostalCodeSubsidyCount(postalCode){
    let amount = 0;
    if(postcodes10000Regeling.includes(postalCode)) amount++;
    if(postcodesWaardedalingsregeling.includes(postalCode)) amount++;
    return amount;
}

function UnsupportedQuestionType() {
    return <div>Unsupported question type</div>;
}

// Shown when first visiting.
function WizardEntry(props){
    return(
        <div className='WizardEntry'>
            <div className='Header'>
                <div>
                    <h1 className='HeaderInfoMain'>Doe nu onze gratis subsidiecheck!</h1>
                    <h3 className='HeaderInfoSecondary'>Binnen drie dagen vrijblijvend advies.</h3>
                </div>
                <div>
                    <button className='WizardButton' onClick={() => props.onComplete()}>Gratis subsidiecheck</button>
                </div>
            </div>
            <div className='Divider'></div>
            <div className='Benefits'>
                <div>
                    <div className='BenefitsCheckmark'></div>
                    <div className='Benefit'>Ontdek de mogelijkheden voor uw woning</div>
                </div>
                <div>
                    <div className='BenefitsCheckmark'></div>
                    <div className='Benefit'>Loop geen vergoedingen mis</div>
                </div>
            </div>
        </div>
    )
}

export default function Wizard() {
    const [wizardOpen, setWizardOpen] = useState(false);
    const [currentQuestionIndex, setQuestionIndex] = useState(0);
    const [questions, setQuestions] = useState(initialQuestions);
    const [currentQuestion, setCurrentQuestion] = useState(questions[currentQuestionIndex]);
    const [showExit, setShowExit] = useState(false);

    useEffect(() => {
        setCurrentQuestion(questions[currentQuestionIndex]);
    }, [currentQuestionIndex, questions]);

    function prevQuestion() {
        // Set current question fields to empty.
        currentQuestion.fields.map((field) => {
            if(currentQuestion.type === questionTypes.checklist) delete field.selected;
            if(currentQuestion.type === questionTypes.input) delete field.answer;
        });

        let prevIndex = Math.max(currentQuestionIndex - 1, 0);
        let newIndex = prevIndex;

        // Recursively check each previous question. Keep decrementing the prevIndex if that question is not answered yet,
        // stop until one is reached that did have an answer; or the question-index === 0.
        if(prevIndex !== 0){

            // Recursive method
            const GetPrevIndex = (pIndex) => {
                if(pIndex === 0) return pIndex;
                const q = questions[pIndex];

                for (let index = 0; index < q.fields.length; index++) {
                    const field = q.fields[index];
                    if (field.selected || field.answer) {
                        return pIndex;
                    }
                }
                pIndex--;

                return GetPrevIndex(pIndex);
            };

            newIndex = GetPrevIndex(prevIndex);
        }

        setQuestionIndex(newIndex);
        setCurrentQuestion(questions[newIndex]);
    }

    function nextQuestion() {

        // Check if all fields are answered (differs per type).
        let hasAnswered;
        switch (currentQuestion.type) {
            case questionTypes.checklist:
                hasAnswered = false;
                for (let index = 0; index < currentQuestion.fields.length; index++) {
                    const field = currentQuestion.fields[index];
                    if (field.selected) {
                        hasAnswered = true;
                        break;
                    }
                }   
                break;
            case questionTypes.input:
                currentQuestion.fields.forEach(field => {
                    hasAnswered = true;
                    if(!field.answer) hasAnswered = false;
                });
                break;
            default:
                break;
        }
        if (!hasAnswered) return;

        // Check each field for certain options.
        for (let index = 0; index < currentQuestion.fields.length; index++) {
            const field = currentQuestion.fields[index];
            if (field.selected || field.answer) {

                // Go directly to question at index 'skipTo'
                if(field.skipTo){
                    setQuestionIndex(field.skipTo);
                    return;
                }
            }
        }
        
        // Clamp nextIndex between 0 and 'amount of questions'.
        const nextIndex = Math.min(Math.max(currentQuestionIndex+1, 0), questions.length-1);
        setQuestionIndex(nextIndex)
    }

    function updateQuestion(updatedField, fieldIndex) {
        const updatedQuestions = questions.map((question, qIndex) => {
            if (qIndex === currentQuestionIndex) {
                const updatedFields = question.fields.map((field, fIndex) => {
                    if (fIndex === fieldIndex) {
                        return { ...field, ...updatedField };
                    }
                    return field;
                });
                return { ...question, fields: updatedFields };
            }
            return question;
        });
    
        setQuestions(updatedQuestions);
        setCurrentQuestion(updatedQuestions[currentQuestionIndex]);
    }

    function RenderQuestionFields({totalSubsidy}){
        let QuestionFieldComponent;
        switch (currentQuestion.type) {
            case questionTypes.checklist:
                QuestionFieldComponent = Checklist;
                break;
            case questionTypes.input:
                QuestionFieldComponent = Input;
                break;
            case questionTypes.form:
                QuestionFieldComponent = Form;
                break;
            default:
                QuestionFieldComponent = UnsupportedQuestionType;
                break;
        }

        return (
            <QuestionFieldComponent question={currentQuestion} currentQuestionIndex={currentQuestionIndex} nextQuestion={nextQuestion} prevQuestion={prevQuestion} updateQuestion={updateQuestion} totalSubsidy={totalSubsidy} questions={questions} setShowExit={setShowExit}/>
        )
    }

    if(showExit){
        return (
            <div className='Wizard Open'>
                <div className='CategoryPanel'>
                    {Object.entries(categories).map((category, index) => (
                        <div key={index} className={`Category ${category[1] === currentQuestion.category ? 'Active' : category[1] < currentQuestion.category ? 'Completed' : ''}`}>
                            <div className='CategoryNumber'>{category[1]}</div>
                            <div className='CategoryInfo'>
                                <div className='Step'>Stap {category[1]}</div>
                                <div className='Name'>{category[0].charAt(0).toUpperCase() + category[0].slice(1)}</div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='QuestionPanel'>
                    <div className="Question">Bedankt voor invullen van de subsidiecheck.</div>
                    <div className='FollowUpConclusion'>We proberen zo snel mogelijk contact met u op te nemen.</div>
                </div>
            </div>
        )
    }

    if (!wizardOpen) {
        return (
            <div className='Wizard'>
                <WizardEntry onComplete={() => setWizardOpen(true)} />
            </div>
        );
    }

    let FollowUpConclusion;
    let hasAnswered;
    let showSubsidy;
    for (let index = 0; index < currentQuestion.fields.length; index++) {
        const field = currentQuestion.fields[index];
        if (field.selected || field.answer) {
            hasAnswered = true;
            if(field.FollowUpConclusion) FollowUpConclusion = field.FollowUpConclusion;
            if(field.showSubsidy) showSubsidy = true;
            break;
        }
    }

    let totalSubsidy = 0;
    questions.map((q)=>{
        for (let index = 0; index < q.fields.length; index++) {
            const field = q.fields[index];
            if (field.selected) {
                if(field.increaseSubsidie) totalSubsidy+=1;
            }
            if(field.answer){
                const postalCode = Number(field.answer.replace(/\D/g,''));
                totalSubsidy += GetPostalCodeSubsidyCount(postalCode);
            }
        }
    })

    return (
        <div className='Wizard Open'>
            <div className='CategoryPanel'>
                {Object.entries(categories).map((category, index) => (
                    <div key={index} className={`Category ${category[1] === currentQuestion.category ? 'Active' : category[1] < currentQuestion.category ? 'Completed' : ''}`}>
                        <div className='CategoryNumber'>{category[1]}</div>
                        <div className='CategoryInfo'>
                            <div className='Step'>Stap {category[1]}</div>
                            <div className='Name'>{category[0].charAt(0).toUpperCase() + category[0].slice(1)}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='QuestionPanel'>
                <div className="Question">{currentQuestion.question}</div>
                <RenderQuestionFields totalSubsidy={totalSubsidy} />
                <div className='FollowUpConclusion'>
                    {FollowUpConclusion
                        ? FollowUpConclusion.Conclusion
                        : ''
                    }
                    {showSubsidy
                        ? totalSubsidy > 0 
                            ?   <div>U heeft mogelijk recht op <span style={{fontWeight: 'bold'}}>{totalSubsidy}</span> subsidie(s). Het Subsidie Loket adviseert, begeleidt en ontzorgt u graag bij het regelen van deze subsidies.</div>
                            :   <div>U heeft geen directe subsidiekans zichtbaar, maar laten we samen de mogelijkheden verkennen. Contacteer ons.</div>
                        : ''
                    }
                </div>
                <div className='Pagination'>
                    {
                        currentQuestionIndex < questions.length-1 && (!FollowUpConclusion || !FollowUpConclusion.HideNextStepButton) && hasAnswered
                        ?   <button className='WizardButton' onClick={() => nextQuestion()}>Volgende stap</button>
                        :   ''
                    }
                    {
                        currentQuestionIndex > 0
                        ?   <button onClick={() => prevQuestion()}>Vorige stap</button>
                        :   ''
                    }
                    
                </div>
            </div>
            
        </div>
    );
}