import React from 'react';
import ReactDOM from 'react-dom/client';
import SubsidieCheck from './SubsidieCheck';

import './index.css';
import './fonts/Poppins/Poppins-Medium.ttf'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SubsidieCheck />
  </React.StrictMode>
);