import './styles.css';
import './styles-small.css';
import Wizard from './Wizard';


// Entry-point of this tool.
export default function SubsidieCheck() {
  return (
    <div className="App">
      <Wizard />
    </div>
  );
}